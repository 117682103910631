@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-submenu-selected, .ant-menu-submenu-selected > [style="padding-left: 48px;"] {
  background-color: #2D2F66 !important;
}

.ant-menu-submenu-selected > div > span, .ant-menu-submenu-selected > div > svg ,.ant-menu-submenu-selected > div > i{
  color: #F7F7F7;
}

