
.desktop-screen{
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.form-container{
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-container-login{
  width: 50%;
  height: 100vh;
}

.img-container-login img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-form {
  max-width: 100%;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.ant-btn-primary {
  background-color: #2D2F66;
  border-color: #2D2F66;
}

.img-logo-container{
  width: 300px;
  height: 70px;
}

@media (max-width: 768px){
  .form-container{
    width: 100%;
  }
  .img-container-login{
    width: 0%;
  }
}

@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@font-family: 'Nunito', sans-serif;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);