@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
.tarjet {
    padding: 1rem 0;
    margin: 1rem 0;
    border-radius: 10px;
    &-img {
        padding: 1rem 0;
        display: grid;
        place-content: center;
        border-right: 1px solid #CDDFFF;
    }
    &-container {
        padding: 0 1rem;
        & p:not(.tarjet-status):not(.tarjet-info) {
            margin: 0;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #2D2F66;
            overflow-wrap: break-word;
        }
        & span {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 16px;
            color: #686868;
        }
    }
    &-info {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 20px;
        color: #2D2F66;
        margin-bottom: 0.75rem;
    }
    &-status {
        display: flex;
        align-items: center;
        padding: 1px 8px;
        color: #FFFFFF;
        width: fit-content;
        height: 22px;
        background: #2460B7;
        border-radius: 6px;
    }
}