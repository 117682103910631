@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css');

:root {
	--black: #1A171E;
	--white: #F7F7F7;
	--full-white: #ffffff;
	--gray: #F4F4F4;
	--gray-50: #EEEEEE;
	--full-gray: #D4D4D4;
	--blue-100: #2460B7;
	--blue-200: #2D2F66;
	--yellow-100: #F2A706;
}

body{
	font-family: 'Nunito', sans-serif !important;
	background-color: var(--white) !important;
}

.btn {
	font-weight: bold !important;
}

h1{
    font-size: 3.052em;
}

h2 {
    font-size: 2.441em;
}

h3{
    font-size: 1.953em;
}

h4{
    font-size: 1.563em;
    font-weight: 500;
}

h5{
    font-size: 1.25em;
}

h6{
    font-size: 1.05em;
}

p{
    font-size: 1em;
    color: #000000;
}

caption{
    font-size: 0.7708em;
}

ul, li {
    font-size: 1.05em;
    font-weight: 500;
}

.card-transparent {
    background-color: transparent;
}

/* ----------------------------------------------- */

.byp-image {
	object-fit: cover;
}

.byp-img-avatar {
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
}

 .byp-bg-full-white {
	background-color: #ffffff;
 }

 .byp-bg-white {
	background-color: var(--white);
 }

 .byp-bg-blue-100 {
	 background-color: var(--blue-100);
	 color: #ffffff;
	 font-weight: bold;
 }

  .byp-blue-100-c {
	 color: var(--blue-100);
	 font-weight: bold;
	 text-align: center;
	 font-size: 2.813rem;
 }
 .byp-black-a {
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	color: #1A171E;
	font-family: Nunito;
	font-style: normal;
}

.byp-sub-black{
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
}
.byp-text-cont-blue{
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

 .byp-ubi-btn {
 	display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;

position: absolute;
width: 262px;
height: 52px;
left: 718px;
top: 230px;
 }

 
 .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
	margin-left: 10px;
 }

 .byp-bg-blue-200 {
	background: var(--blue-200) !important;
 }

 .byp-blue-100 {
	color: var(--blue-100);
 }

 .byp-blue-200 {
	color: var(--blue-200) !important;
 }

 .byp-black {
	color: var(--black);
 }

 .byp-yellow-100 {
	color: var(--yellow-100)
 }

 /* .byp-bg-gray {
	background-color: #F4F4F4;
 } */

 .byp-gray-50 {
	 color: #EEEEEE;
 }

 .byp-poster {
	max-width: calc(31.25rem - 5vw);
	min-width: 22rem;
 }

 .byp-on-something {
	z-index: 2;
 }

 .byp-privacy-block {
	z-index: 0;
	margin-top: -3rem;
 }

 @media screen and (min-width: 920px) {
	 .byp-privacy-block {
		z-index: 0;
		margin-top: -15rem !important;
	 }
 }

.bg-image-vertical {
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: right center;
	background-size: auto 100%;
}
	
@media (min-width: 1025px) {
	.h-custom-2 {
		height: 100%;
	}
}

.byp-topbar-logo {
	width: 16rem;
}
/**
 * Buttons
**/
.byp-topbar-button {
	background: #F7F7F7;
	color: #2460B7;
	border: 1.15942px solid #2460B7;
	box-sizing: border-box;
	border-radius: 6.95652px;
	font-weight: bold;
}

.byp-topbar-button:hover {
	background: #2D2F66;
	color: #ffffff;
}

.byp-btn-blue-100 {
	border-radius: 6.95652px;
	color: #ffffff;
	font-weight: bold;
	background-color: var(--blue-100);
}

.byp-btn-gray-100 {
	border-radius: 50% !important;
    font-size: .9rem;
    color:gray;
    background-color:var(--gray-50);

}

.byp-btn-blue-100:hover {
	color: #ffffff;
	background-color: var(--blue-100);
}

.byp-btn-blue-100-outline {
	border: 1px solid #2460B7;
	border-radius: 6.95652px;
	color: #2460B7;
	font-weight: bold;
	background-color: transparent;
}

.byp-btn-blue-100-outline:hover, .byp-btn-blue-100-outline:active, .byp-btn-blue-100-outline.active{
	background-color: #2460B7 !important;
	color: #ffffff !important;
}

.byp-btn-blue-100-outline:focus {
	color: #2460B7;
}

/*  */
.byp-list-footer {
	line-height: 0.8;
}

.byp-centered-row{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.byp-centered {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@media screen and (min-width: 920px) {
	.byp-centered-space {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

.byp-input-login {
	border-radius: 0.8rem !important;
}

.m-05 {
	margin-top: .5rem;
	margin-bottom: .5rem;
}

.mb-05 {
	margin-bottom: .5rem;
}

.mbt05 {
	margin-top: .5rem;
}

.mr-05 {
	margin-right: .5rem;
}
/* Main Page CSS */
.byp-input {
	background-color: #F4F4F4;
	max-width: 100%;
	height: 2.5rem;
	outline: none;
}

.byp-input-border {
	background-color: #F4F4F4;
	border: solid 1px var(--full-gray) !important;
	border-radius: .5rem;
	max-width: 100%;
	height: 2.5rem;
	outline: none;
}

.byp-input:focus {
	outline: none;
	border-color: #f3a006;
}

.byp-texarea {
	background-color: #F4F4F4;
	border-radius: .5rem;
	padding-left: 0.5rem;
}

.byp-texarea:focus {
	border-color: #f3a006;
	outline: none;
}

.byp-input-group {
	border: solid 1px #D4D4D4;
    border-radius: 0.5rem;
	margin-left: 0.4rem;
	margin-right: 0.4rem;
}

.byp-round {
	border-radius: 0.6rem;
}

.byp-card {
	background-color: var(--full-white);
	border-radius: 1.3rem;
	margin-top: 1rem;
	background-position: center;
	background-repeat: no-repeat;
    background-size: cover;
	min-height: 12rem;
	overflow: hidden;
}

.byp-item-card {
	border-radius: 1rem;
	background-position: center;
	background-repeat: no-repeat;
    background-size: cover;
	min-height: 16rem;
	overflow: hidden;
}

.byp-img-card-header {
	min-height: 12rem;
}

.bg-overlay {
	background-color: #1a171e54;
	height: 16rem;
}

.border-top-rounded {
	border-top-right-radius: 1rem;	
	border-top-left-radius: 1rem;
}

.byp-slide-img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.price-before {
	text-decoration: line-through;
}

/* ------------------------------------- MODAL --------------------------------------- */
.modal-content {
	border-radius: 1.3rem;
}

.modal-header .close {
	color: var(--full-gray) !important;
}

label, .form-check-label {
	color: var(--black);
	font-weight: 600;
}
/* --------------------------------- inputs ------------------------------------------ */
.form-check-input {

}

/* ----------------------------------- TOOGLE ---------------------------------------- */
.toggle {
	border-radius: 1rem !important;
	background: #D4D4D4 !important;
	width: 3rem !important;
	height: 1rem !important;
}

.toggle-group {
	justify-content: center;
	display: grid;
	align-items: center;
}

.btn-byp-toggle-on {
	border: solid 1px;
	border-color: #F2A706;
}
.btn-byp-toggle-off {
	border: solid 1px;
	border-color: #D4D4D4;
}

.toggle-handle {
	height: 0.9rem !important;
	width: 0.9rem !important;
	border-radius: 50% !important;
}

.btn-byp-toggle-off .toggle-handle{
	margin-left: 1rem;
}

.btn-byp-toggle-on .toggle-handle{
	margin-right: 1rem;
	background-color: #F2A706 !important;
}

/* ------------------------------------------- Radio Buttons --------------------------------- */
.byp-radio-container {	/* The byp-radio-container */
	display: block;
	position: relative;
	padding-left: 1.5rem;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
  
.byp-radio-container input {	/* Hide the browser's default radio button */
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
  
.checkmark {	/* Create a custom radio button */
	position: absolute;
	top: 2px;
	left: 0;
	height: 1rem;
	width: 1rem;
	background-color: #eee;
	border-radius: 50%;
} 

.byp-radio-container:hover input ~ .checkmark {	/* On mouse-over, add a grey background color */
	background-color: #ccc;
}
  
  /* When the radio button is checked, add a tarnasparent background */
.byp-radio-container input:checked ~ .checkmark {
	background-color: transparent;
	border: solid 1px #F2A706;
}
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
  
  /* Show the indicator (dot/circle) when checked */
.byp-radio-container input:checked ~ .checkmark:after {
	display: block;
}
  
  /* Style the indicator (dot/circle) */
.byp-radio-container .checkmark:after {
	top: 1px;
	left: 1px;
	width: .74rem;
	height: .74rem;
	border-radius: 50%;
	background: #F2A706;
}