/**
 *  Layout CSS
 */

 

 @media (max-width: 991px){
    .byp-dashboard-menu  .ant-menu-item , .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
        background-color: var(--full-white);
    }  
 }

.byp-dashboard-content {
    min-height: 90vh;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    background: var(--gray);
    border: solid .15rem var(--full-gray);
    padding: 1.5rem;
    margin: 0 20px;
}

.byp-dashboard-footer {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: var(--white);
    border-bottom: solid .15rem var(--full-gray);
    border-left: solid .15rem var(--full-gray);
    border-right: solid .15rem var(--full-gray);
    padding: 0 1rem 1rem 1rem;
    background-color: var(--white);

    margin: 0 20px;
}

.dashboard-main-logo {
    width: 40%;
    margin-bottom: 2rem;
}

.byp-sidebar {
    min-height: 90vh;
    ul {
        background-color: var(--white);
    }
}

.dashboard-layout .logo-small {
    width: 100%;
}

.byp-dashboard-content-end {
    min-height: 92vh;
    // height: 100%;
    border-radius: 1rem;

    background: var(--full-gray);
    border: solid .15rem var(--full-gray);
    padding: 1.5rem;
}

.byp-dashboard-quote-created-modal {
    background-color: var(--white);
    padding: 2rem;
    border-radius: .8rem;
    max-width: 25rem;
    .ant-btn, .byp-btn {
        height: 2.4rem;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }
}

/**
 * Menu CSS
 */
.byp-dashboard-menu {
    background-color: var(--white);
    margin-right: 1rem;
    border: var(--full-gray);
    border-style: solid;
    border-width: thin;
}

.byp-dashboard-menu .ant-menu-item, .byp-dashboard-menu .ant-menu-submenu-title {
    border-bottom: solid .15rem var(--full-gray);
}

.byp-dashboard-menu .ant-menu-item-selected, .ant-menu-item:hover {
    background-color: var(--blue-200) !important;
    color: var(--full-white) !important;
    font-weight: bold;
    a {
        color: var(--full-white) !important;
    }
}

.byp-dashboard-menu .ant-menu-item, .ant-menu-submenu-title {
    color: var(--blue-200);
    font-weight: bold;
    margin: 0 !important;
}

.ant-menu-submenu-selected > [style="padding-left: 72px;"] {
    background-color: #2D2F66 !important;
}

.byp-dashboard-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    background-color: #CDDFFF;
}

.ant-layout.ant-layout-has-sider {
    background-color: var(--white);
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    .site-layout-content {
        padding: 0 .5rem 0 .5rem;
    }
}

/**
 * Inputs
 */
 .content-quoter .ant-input-number-input {
    height: 33px;
 }

 .ant-input-number, .ant-input-number-input {
    border-radius: .3rem;
 }

.content-quoter .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.content-quoter .byp-input, .content-quoter .ant-input,
.byp-dashboard-content .byp-input, .byp-dashboard-content .ant-input,
.byp-dashboard-content .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input-number-input {
    background-color: var(--white);
    border-color: var(--full-gray) !important;
    border-radius: .3rem;
    padding: 0.35rem;
}

.content-quoter .ant-select,
.byp-dashboard-content .ant-select {
    line-height: 1.3;
}

.content-quoter .ant-select-selector,
.content-quoter .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.byp-dashboard-content .ant-select-selector,
.byp-dashboard-content .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // height: 2.2rem;
    min-height: 2.2rem;
}

.content-quoter .ant-select-single .ant-select-selector .ant-select-selection-item,
.byp-dashboard-content .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 24px;
}

.byp-dashboard-content .ant-input-group-addon:first-child {
    background-color: var(--full-gray);
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
}

.byp-input-gray .ant-input {
    background-color: var(--gray-50);
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
}

.ant-input-number-group-addon {
    background-color: var(--gray-50);
    border-bottom-left-radius: .3rem;
    border-top-left-radius: .3rem;
}

.dashboard-media-uploader .ant-upload {
    width: 100%;
}

.byp-table-dashboard {
    height: 100%;
    width: 100%;
    background-color: transparent;

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        background-color: var(--gray-50);
        color: var(--gray-100);
        border-bottom: solid .1rem #d9d9d9;
    }

    .ant-table-title {
        background-color: var(--full-gray);
    }
}

.byp-table-dashboard.table-sm {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
        padding: 12px 12px;
    }
}

.byp-input-as-label, .byp-input-as-label:focus, .byp-input-as-label:hover{
    background-color: transparent !important;
    border: none;
}

.all-users__confirm {
    gap: 0.25rem;
    padding: 1rem 0.5rem;
}

.all-users__confirm:active {
    background-color: var(--white);
}

/**
 * Step Header CSS
*/
.byp-step-buttoon-header .ant-steps-item {
    flex: inherit;
    
}

.byp-step-buttoon-header .ant-steps-item-container {
    text-align: center;
    width: fit-content;
    .ant-steps-item-title, .ant-steps-item-title a {
        font-size: 10px;
        text-align: center;
        color: var(--full-white) !important;
        font-weight: 600;
        padding-left: .4rem;
        padding-right: .4rem !important;
    }
}

.byp-step-buttoon-header .ant-steps-item-wait .ant-steps-item-container {
    border-radius: 50%;
    width: 1.5rem;
    background-color: var(--full-gray);

    .ant-steps-item-icon {
        display: none;
    }

    .ant-steps-item-title::after{
        background-color: var(--full-gray);
        width: 0;
    }
}

.byp-step-buttoon-header .ant-steps-item-active .ant-steps-item-container {
    background-color: var(--blue-50);
    border-radius: 50%;
    width: 1.5rem;

    .ant-steps-item-icon {
        display: none;
    }

    .ant-steps-item-title::after {
        background-color: var(--blue-50);
        width: 0;
    }
}

.byp-step-buttoon-header .ant-steps-item-finish .ant-steps-item-container {
    background-color: var(--blue-100);
    border-radius: 50%;
    width: 1.5rem;
    .ant-steps-item-icon {
        height: 18px;
        width: 18px;
        margin-top: .2rem;
        margin-right: .2rem;
        display: none;
    }
}

.byp-step-buttoon-header .ant-steps-finish-icon {
    svg {
        height: 18px;
        width: 7px;
    }
}

.byp-dashboard-steps .ant-steps-vertical {
    flex-direction: row;
    .ant-steps-item {
        .ant-steps-item-icon {
            display: block;
        }
        .ant-steps-item-content {
            display: none;
        }
    }

}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none;
}

/**
 *
 */
.byp-dashboard-header {
    background-color: var(--full-gray);
    // padding: .3rem 1rem .3rem 1rem;
    padding: 0rem 0rem 0rem 1rem;
    border-radius: .4rem;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: var(--gray-50)
    }
}

.byp-bordered-left {
    border-left: solid .15rem var(--gray-100);
}

.byp-list-item {
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: solid var(--gray-50) 1px;
}

.byp-list-course-item {
    border: solid var(--gray-50) 1px;
    margin-bottom: 1rem;
}

.byp-list-course-item-content {
    padding: 1rem 1rem 1rem 1rem;
}

.byp-list-course-item-footer {
    border-top: solid var(--gray-50) 1px;
    width: 100%;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
}

/**
 * Collapse CSS
*/
.ant-collapse {
    background-color: transparent;
    border: none;
}

.byp-course-overview-card .ant-collapse-header {
    padding: .3rem !important;
    border: none !important;
}

.byp-course-overview-card .ant-collapse-content-active,
.byp-course-overview-card .ant-collapse-content-box,
.byp-course-overview-card table,
.byp-course-overview-card .ant-table-container {
    border: none !important;
}

.ant-collapse-header, .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    color: var(--black);
    background-color: var(--gray-50);
    border-top: solid 1px var(--full-gray);
    border-left: solid 1px var(--full-gray);
    border-right: solid 1px var(--full-gray);
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
}

.ant-collapse-content-active {
    margin-bottom: 2rem;
    overflow: hidden;
}
.ant-collapse-content-box, .ant-collapse-content-active {
    background-color: transparent;
    border: solid 1px var(--full-gray);
}

.course-prices .ant-collapse-content-box {
    padding: 0;
}

.byp-hr {
    border-top: solid 1px var(--full-gray);
    position: relative;
    left: -3%;
    width: 106%;
}

.ant-row .byp-hr {
    min-width: 106%;
}

.byp-panel-footer {
    position: relative;
    left: -3%;
    width: 106%;
    background-color: var(--full-white);
    bottom: -17px;
    padding: 1rem;
    border-top: solid 1px var(--full-gray);

    display: flex;
    justify-content: end;
}

.byp-header-centered .ant-collapse-header {
    justify-content: center;
}

// Borderless
.borderless .ant-collapse-content-box,
.borderless .ant-collapse-content,
.content-quoter .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content,
.content-quoter .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    border: none;
}

.content-quoter .ant-collapse-heade.ant-collapse-header, .content-quoter .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border: none;
    padding: .2rem;
}

// Modal
.byp-map-modal {
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-close {
        display: none;
    }
}

.byp-confirm-modal {
    width: 25rem;
    .ant-modal-body {
        text-align: center;
    }
    .ant-modal-footer {
        border-top: none;
    }
}

.p-05 {
    padding: .5rem;
}

// Table Section

.ant-table-container {
    overflow: auto;
}

.byp-mw-150 {
    max-width: 150px;
}

.ant-progress-inner-small,
.ant-progress-inner-small .ant-progress-inner {
    width: 35px !important;
    height: 35px !important;
    font-size: 10px !important;
    font-weight: bold !important;
}

.ant-progress-text{
    color: #2D2F66 !important;
    font-size: 8px !important;
}

.button-select-picture .btn-upload {
    background-color: #2D2F66;
    color: #FFFFFF;
    border-radius: 6px;
    padding: 4px 15px;
    border: 1px solid #2D2F66;
    font-weight: 700;
}

.button-select-picture .txt-upload {
    margin-left: 0.5rem;
}

.extra-info-form {
    .select-campus {
        max-width: 300px;
    }
}