@text-color-secondary-dark: #2D2F66;
@input-color-blue: #2460B7;

:root {
  --black: #1A171E;
  --blue-50: #6495ED;
  --blue-100: #2460B7;
  --blue-200: #2D2F66;
  --full-gray: #D4D4D4;
  --full-white: #ffffff;
  --gray: #F4F4F4;
  --gray-50: #EEEEEE;
  --gray-100: #686868;
  --white: #F7F7F7;
  --yellow-100: #F2A706;
  --red: #c0233d;
  --background: #ffffff;
}