.byp-template-radio {
    display: block;
}

.byp-template-radio .ant-radio .ml{
    left: 342px;
}

.byp-template-label  .p-absolute{
    position: absolute;
    left: 9px;
}

.byp-input {
    width: 100%;
}

.ant-input {
    padding: .5rem;
    color: var(--black);
}

.ant-select {
    line-height: 2.3;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 2.45rem;
    padding-top: .2rem;
}

.ant-result-title{
    color: #2D2F66;
    font-weight: 500;
    font-size: 25.5462px;
}

.ant-select-selection-item{
    // background: white;
}
.ant-select-selector{
    height: auto ! important;
}
.ant-input-number-input{
    background : white;
}

@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@font-family: 'Nunito', sans-serif;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);