.byp-btn {
    padding: .5rem 1.5rem .5rem 1.5rem;
    border-radius: .4rem;
    margin: .5rem;
    padding: 0.25rem;
}

.byp-btn-sm {
    padding: .25rem .5rem .25rem .5rem;
    border-radius: .4rem;
}

.byp-btn-blue-200, .byp-btn-blue-200:hover {
    background-color: var(--blue-200);
    color: var(--full-white);
    font-size: small;
    font-weight: bold;
}

.byp-btn-blue-100, .byp-btn-blue-100:hover, .byp-btn-blue-100:focus {
    background-color: var(--blue-100);
    color: var(--full-white);
    font-size: small;
    font-weight: bold;
}

.byp-btn-blue-100-outline, .byp-btn-back {
    background-color: transparent;
    border: solid .1px var(--blue-100);
    color: var(--blue-100);
    font-weight: bold;
    font-size: small;
    margin-left: .5rem;
    margin-right: .5rem;
}

.byp-btn-gray-100-outline{
    background-color: #edf5f7;
    border: solid .1rem #9CA6A8;
    color:gray;
    font-weight: bold;
    font-size: small;
    margin-left: .5rem;
    margin-right: .5rem;
}

.byp-btn-red-100, .byp-btn-red-100:hover, .byp-btn-red-100:focus {
    background-color: var(--red);
    color: var(--full-white);
    font-size: small;
    font-weight: bold;
}

.byp-br-4 {
    border-radius: .4rem !important;
}

.byp-btn-back {
    // background-color: transparent;
}

.byp-link, .byp-link span {
    border: none;
    text-decoration: underline;
    color: var(--blue-100);
    font-weight: bold;
    background-color: transparent;
}

.byp-link:hover, .byp-link span:hover {
    background-color: transparent;
    color: var(--blue-200);
}

.byp-btn-media-upload {
    border-radius: 10px;
    background: var(--blue-200);
    color: #fff;
    border: solid .1rem var(--full-gray);
    width: 100%;
    display: flex;
    min-height: 3rem;
    cursor: pointer;

    .btn-media-img {
        width: 30%;
    }
    
    .btn-media-text {
        width: 70%;
    }
}

.text-blue-first{
    background-color: var(--blue-100); 
    color: white;
    border-top-left-radius: 5px; 
    border-bottom-left-radius: 5px; 
    align-self: center; 
    border: solid; 
    border-color: var(--blue-200); 
    border-width: 1px;
}

.text-gray-first{
    background-color: lightgray; 
    color: slategray;
    border-top-left-radius: 5px; 
    border-bottom-left-radius: 5px; 
    align-self: center; 
    border: solid; 
    border-color: slategray; 
    border-width: 1px;
}

.text-gray-second{
    background-color: lightgray; 
    color: slategray;
    border-top-right-radius: 5px; 
    border-bottom-right-radius: 5px;
    align-self: center; 
    border: solid; 
    border-color: slategray; 
    border-width: 1px;
}

.text-blue-second{
    background-color: var(--blue-100); 
    color: white;
    border-top-right-radius: 5px; 
    border-bottom-right-radius: 5px; 
    align-self: center; 
    border: solid; 
    border-color: var(--blue-200); 
    border-width: 1px;
}

.byp-btn-media-upload:hover{
    background: var(--blue-100);
    color: #fff;
    transition: background .5s ease;
}

.byp-dashboard-content button, .byp-dashboard-footer button {
    border-radius: .4rem !important;
}

.btn-icon {
    padding:  0 .3rem !important;
}
.byp-btn-delete {
    background: transparent;
    border: none;
    color: red;
}

.byp-btn-edit {
    background: transparent;
    border: none;
    color: blue;
}

.byp-btn-transparent {
    background: transparent;
    border: none;
}

.byp-dashboard-content .byp-btn-list-function {
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
    font-size: .9rem;
    color: var(--full-white);
    background-color: #D4D4D4;
}
.byp-dashboard-content .byp-btn-list-function-more{
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
    font-size: .9rem;
    color: var(--white);
 }

 .byp-btn-list-function-add{
    background-color: #F4F4F4;
    border: 1px solid #2460B7;
    color: #2460B7;
    font-weight: bold;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 6px;
    width: 131px;
    height: 32px;
    svg{
        display: block !important;
    }

 }

 .byp-btn-list-function-del{
    background-color: #F4F4F4;
    border: 1px solid #686868;
    color: #686868;
    font-weight: bold;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 6px;
    width: 131px;
    height: 32px;
    align-items: center;
    align-content: center;
    justify-content: center;
    svg{
        display: flex !important;
    }

 }


.ant-input-search-button,
.byp-input-gray .ant-input-search-button {
    border: none;
    background-color: var(--gray-50);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
    height: 2.2rem;
}

.ant-input-search-button,
.byp-input-gray .ant-input-affix-wrapper {
    border-top-left-radius: .3rem !important;
    border-bottom-left-radius: .3rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--gray-50);
    border: none;
}

.byp-input-gray .ant-input-search-button {
    border: solid 1px var(--full-gray);
}

.byp-btn-category,
.byp-btn-category:active,
.byp-btn-category:focus,
.byp-btn-category:hover {
    border-radius: .3rem !important;
    border: solid 1px var(--blue-100);
    background-color: var(--blue-100);
    color: var(--full-white);
    font-weight: bold;
}

.byp-btn-category.ant-btn-background-ghost {
    border-radius: .3rem !important;
    border: solid 2px var(--blue-100);
    background-color: transparent;
    color: var(--blue-100);
    font-weight: bold;
}

.byp-btn-yellow,
.byp-btn-yellow:active,
.byp-btn-yellow:focus,
.byp-btn-yellow:hover {
    background-color: #FFCC00;
    color: var(--full-white);
    font-weight: bold;
}
.byp-btn-yellow-blue,
.byp-btn-yellow-blue:active,
.byp-btn-yellow-blue:focus,
.byp-btn-yellow-blue:hover {
    background-color: var(--yellow-100);
    color: #2D2F66;
    font-weight: bold;
    svg{
        display: block !important;
    }
}

.byp-btn-other{
    
    width: 110px !important;
    margin-left: 1.2rem;
    svg{
        display: block !important;
    }
}


.byp-btn-expanded,
.byp-btn-not-expanded {
    background-color: var(--white);
    font-size: small;
    color: var(--blue-100);
    border-radius: .4rem !important;
}

.byp-btn-not-expanded {
    .eye-opened {
        display: none;
    }
    .eye-closed {
        display: inline-block;
    }
}
.byp-btn-not-expanded:hover {
    .eye-closed {
        display: none;
    }
    .eye-opened {
        display: inline-block;
    }
}

.byp-btn-category-m {
    margin-left: 8px;
    margin-right: 8px;
}

.discount-status__Aproved {
    background-color: var(--blue-100);
    color: #fff;
}

.discount-status__Active {
    background-color: var(--blue-100);
    color: #fff;
}

.discount-status__Created {
    background-color: var(--blue-50);
    color: #fff;
}

.discount-status__Inactive {
    background-color: var(--red);
    color: #fff;
}

.discount-status__toaprove {
    background-color: var(--yellow-100);
    color: #fff;
}

.discount-status__FINISHED {
    background-color: var(--full-gray);
    color: #000;
}