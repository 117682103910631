.totals-general {
    &__card {
        background: #FFFFFF;
        padding: 12px 22px;
        border-radius: 10px;
        & > p {
            margin: 0 !important;
        }
    }
    &__quotes {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 3rem;
        line-height: 71px;
        color: #2D2F66;
    }
    &__type {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;
        color: #686868;
    }
}

.chart-doughnut {
    &__container {
        background: #FFFFFF;
        border-radius: 6px;
        width: 100%;
    }
    &__header {
        padding: 18px 13px;
        border-bottom: 2px solid #EEEEEE;
        & > h4,
        & > div > p {
            margin: 0;
        }
        & > h4 {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 1.325rem;
            line-height: 29px;
            color: #1A171E;
        }
        & > div > p {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 2.12rem;
            line-height: 46px;
            color: #1A171E;
        }
        & > div {
            display: flex;
            align-items: center;
        }
        
    }
    &__chart,
    &__types {
        padding: 1.875rem;
    }
    &__types > div {
        display: flex;
        align-items: center;
        gap: 0.625rem;
    }
    &__type-color {
        width: 10px;
        height: 10px;
        border-radius: 9999px;
    }
    &__type-txt {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 0.813rem;
        line-height: 18px;
        color: #1A171E;
        margin: 0;
    }
}

.trends-general {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-bottom: 1rem;
    border-bottom: 1px solid #cddfff;
    &__card {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 12px 22px;
        height: 100%;
        & > p {
            margin: 0;
        }
    }
    &__subtitle {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 0.813rem;
        line-height: 18px;
        color: #686868;
    }
    &__title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 1.875rem;
        line-height: 41px;
        color: #2D2F66;
        word-break: break-word;
    }
}

.general__see-more {
    background: #2D2F66;
    border: 1px solid #2D2F66;
    color: #FFFFFF;
    padding: 4px 15px;
}

.chart__total-quotes {
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 24px;
    & p {
        margin: 0;
    }
    & p:nth-child(1) {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 22px;
        color: #686868;
    }
    & p:nth-child(2) {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 500;
        font-size: 2.12rem;
        line-height: 46px;
        color: #1A171E;
    }
    & p:nth-child(3) {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 19px;
        color: #686868;
    }
}

.border-bottom__blue {
    border-bottom: 1px solid #cddfff;
    padding-bottom: 1rem;
}

.trends {
    &__select-program {
        display: flex;
        align-items: center;
        gap: 10px;
        & > label {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 22px;
            color: #1A171E;
            margin: 0;
        }
        & > .ant-form-item {
            margin: 0;
        }
    }
}

.card {
    &__add-more {
        background: #CDDFFF;
        border-radius: 10px;
        padding: 24px 29px;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
        & > p {
            margin: 0;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 25px;
            color: #686868;
        }
        & > div {
            background: #6495ED;
            color: #FFFFFF;
            height: 30px;
            aspect-ratio: 1;
            display: grid;
            place-content: center;
            border-radius: 9999px;
            font-size: 1rem;
            cursor: pointer;
        }
    }
    &__trends-country {
        background-color: #FFFFFF;
        padding: 12px 22px;
        border-radius: 10px;
        height: 100%;
        & p {
            margin:  0;
        }
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > p {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                font-size: 1.875rem;
                line-height: 41px;
                color: #2D2F66;
            }
        }
    }
    &__program {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 25px;
        color: #686868;
    }
}

.radio_container {
    display: flex;
    gap: 12px;
    align-items: center;
    & p,
    & .ant-radio,
    & span {
        font-weight: 400;
        font-size: 12px;
        line-height: 0.75rem;
        color: #1A171E;
        margin: 0;
    }
}

.summarized-results {
    background-color: #FFFFFF;
    height: 100%;
    border-radius: 10px;
    padding: 12px;
    overflow: hidden;
    & p {
        margin: 0;
    }
    &__country,
    &__type-time
    {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 1vw;
        line-height: 1.41vw;
        color: #686868;
    }
    &__group-first {
        display: flex;
        justify-content: space-between;
    }
    &__group-second {
        display: flex;
        gap: 10px;
        align-items: center;
        & > p {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 2.2vw;
            line-height: 3.11vw;
            color: #2D2F66;
        }
    }
    &__up,
    &__down
    {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size:  0.79vw;
        line-height: 1.13vw;
        color: #FFFFFF;
        border-radius: 6px;
        padding: 1px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        & > svg {
            width: 0.8vw;
        }
    }
    &__up {
        background-color: #34A853;
    }
    &__down {
        background-color: #E42A36;
    }
    &__img-container {
        max-width: 6.5vw;
        object-fit: cover;
    }
    &__img {
        max-width: 6.5vw;
        object-fit: cover;
    }
}

.byp-disabled {
    opacity: 0.5;
    color: #2460B7 !important;
    border: 1px solid #2460B7 !important;
}

.byp-disabled.byp-bg-blue-200 {
    opacity: 0.5;
    border: 1px solid #2D2F66 !important;
    color: #FFFFFF !important;
}