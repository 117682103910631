h1 {
  font-size: 3.052em;
}

h2 {
  font-size: 2.441em;
}

h3 {
  font-size: 1.953em;
}

h4 {
  font-size: 1.563em;
  font-weight: 500;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1.05em;
}

body {
  background: var(--background);
}

.ant-tag {
  border-radius: .4rem;
}

.byp-tag {
  background-color: var(--gray-50);
  color: var(--blue-200);
  border: transparent;
}